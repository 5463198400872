/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AnamnesePatientDetailsForm.vue?vue&type=template&id=1b805972&scoped=true&lang=pug&"
import script from "./AnamnesePatientDetailsForm.vue?vue&type=script&lang=ts&"
export * from "./AnamnesePatientDetailsForm.vue?vue&type=script&lang=ts&"
import style0 from "./AnamnesePatientDetailsForm.vue?vue&type=style&index=0&id=1b805972&lang=scss&scoped=true&"
import style1 from "./AnamnesePatientDetailsForm.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../vui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b805972",
  null
  
)

export default component.exports