/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px","persistent":"","scrollable":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Stammdaten Formular bearbeiten")])]),(_vm.patientInfoFormFieldConfig)?_c(VCardText,{staticClass:"editStammdatenFormular"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Felder")]),_c('div',{staticClass:"fields"},_vm._l((_vm.anamnesePatientDetailsFields),function(field){return _c('div',{staticClass:"field"},[_c('div',{staticClass:"fieldLabel"},[_vm._v(_vm._s(field.label))]),_c('div',{staticClass:"d-flex flex-column"},[_c(VSwitch,{attrs:{"dense":"","hide-details":"","label":"Feld anzeigen","input-value":!_vm.patientInfoFormFieldConfig[field.fieldId].hideField},on:{"change":function($event){return _vm.SetFieldConfig(field.fieldId, "hideField", !$event)}}}),_c(VSwitch,{attrs:{"dense":"","hide-details":"","label":"Pflichtfeld","disabled":_vm.patientInfoFormFieldConfig[field.fieldId].hideField,"input-value":!_vm.patientInfoFormFieldConfig[field.fieldId].optional},on:{"change":function($event){return _vm.SetFieldConfig(field.fieldId, "optional", !$event)}}})],1)])}),0)]):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.emitClose}},[_c('span',{staticClass:"mx-4"},[_vm._v("Fertig")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }