"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZipCodeCity = exports.getZipCodeInfo = void 0;
function getZipCodeInfo(zipCode, country = 'de') {
    return fetch(`https://api.zippopotam.us/${country}/${zipCode}`)
        .then(response => response.json())
        .then((data) => {
        const { places } = data;
        return places[0];
    });
}
exports.getZipCodeInfo = getZipCodeInfo;
function getZipCodeCity(zipCode, country = 'de') {
    return getZipCodeInfo(zipCode, country).then(info => info['place name']);
}
exports.getZipCodeCity = getZipCodeCity;
