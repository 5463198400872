"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markQuestionnaireAsInitialGenerated = exports.generateInitialQuestionnaire = exports.addAllCharlyQuestions = exports.createCharlyQuestionElements = void 0;
const types_1 = require("../../../../types/");
const nanoid_1 = require("../../nanoid");
// export const anamneseQuesitonnaireStore = reactive({
//   state: {
//     elements: [] as IQuestionnaireElement[],
//     questionnaires: [] as IQuestionnaire[],
//   },
//   getElement(elementId: string) {
//     return this.state.elements.find(e => e.id === elementId)!;
//   },
//   addElements(elements: IQuestionnaireElement[]) {
//     this.state.elements.push(...elements);
//   },
//   addElement(elements: IQuestionnaireElement) {
//     this.state.elements.push(elements);
//   },
//   getQuestionnairesThatContainElement(elementId: string) {
//     return this.state.questionnaires.filter(q =>
//       [...q.questionnairePages, q.signaturePage].some(page => page.pageElementIds.includes(elementId)),
//     );
//   },
// });
function createCharlyQuestionElements(charlyQuestions) {
    // convert charly question to questionnaire question
    function convertCharlyQustionToQuestionnaireQuestionElement(charlyQuestion) {
        return {
            type: types_1.QuestionnaireElementType.QUESTION,
            id: 'charlyQuestion_' + String(charlyQuestion.questionID),
            question: {
                question: charlyQuestion.question,
                questionID: charlyQuestion.questionID,
                questionLong: charlyQuestion.questionLong,
                isCharlyQuestion: true,
                optional: true,
                fullWidth: false,
                valueType: types_1.CustomAnamneseQuestionTypes.BOOLEAN,
                valueTypeConfig: {
                    commentFieldOption: 'ifYes',
                },
            },
        };
    }
    return charlyQuestions
        .sort((a, b) => a.position - b.position)
        .map(convertCharlyQustionToQuestionnaireQuestionElement);
}
exports.createCharlyQuestionElements = createCharlyQuestionElements;
function addAllCharlyQuestions(questionnaire, charlyQuestionElements) {
    const defaultQuestionsPerPage = 8;
    let usedQuestionElms = questionnaire.questionnairePages.flatMap(page => page.pageElements);
    let remainingCharlyQuestionElements = charlyQuestionElements.filter(charlyQuestionElement => !usedQuestionElms.find(usedQuestionElm => usedQuestionElm.id === charlyQuestionElement.id));
    for (let i = 0; i < remainingCharlyQuestionElements.length; i += defaultQuestionsPerPage) {
        questionnaire.questionnairePages.push({
            title: '',
            hidden: false,
            pageElements: remainingCharlyQuestionElements.slice(i, i + defaultQuestionsPerPage),
            pagebreak: false,
            id: (0, nanoid_1.roseNanoid)(),
        });
    }
}
exports.addAllCharlyQuestions = addAllCharlyQuestions;
function generateInitialQuestionnaire({ displayTitle, title, overrideId, }) {
    let newQuestionnaire = {
        updatedAt: new Date().getTime(),
        updatedBy: '',
        id: overrideId || (0, nanoid_1.roseNanoid)(6),
        deactivated: false,
        prefillPatientDetails: true,
        prefillQuestions: false,
        recallMonths: 0,
        displayTitle: displayTitle,
        allowExternalAccess: false,
        skipReviewProcess: false,
        title: title,
        questionnairePages: [
            {
                id: (0, nanoid_1.roseNanoid)(6),
                pagebreak: false,
                hidden: false,
                title: 'Persönliche Daten',
                pageElements: [
                    {
                        type: types_1.QuestionnaireElementType.RICH_TEXT,
                        id: (0, nanoid_1.roseNanoid)(6),
                        richText: '<h3>Willkommen in unserer Praxis</h3><p>Bitte füllen Sie den Fragebogen aus.</p>',
                    },
                    {
                        type: types_1.QuestionnaireElementType.PATIENT_INFO_FORM,
                        id: 'PATIENT_INFO_FORM',
                    },
                ],
            },
        ],
        signaturePage: {
            id: (0, nanoid_1.roseNanoid)(6),
            pagebreak: false,
            hidden: false,
            title: 'Unterschrift',
            pageElements: [
                {
                    richText: '<p>Bitte überprüfen Sie Ihre Angaben und unterschreiben Sie das Dokument unten.</p>',
                    type: types_1.QuestionnaireElementType.RICH_TEXT,
                    id: (0, nanoid_1.roseNanoid)(),
                },
                { type: types_1.QuestionnaireElementType.DOCUMENT_PREVIEW, id: (0, nanoid_1.roseNanoid)() },
                {
                    richText: '<p>Mit Ihrer Unterschrift stimmen Sie der elektronischen Übermittlung zu.</p>',
                    type: types_1.QuestionnaireElementType.RICH_TEXT,
                    id: (0, nanoid_1.roseNanoid)(),
                },
                { type: types_1.QuestionnaireElementType.DOCUMENT_SIGNATURE, id: (0, nanoid_1.roseNanoid)() },
            ],
        },
        initialGeneratedHash: '',
    };
    markQuestionnaireAsInitialGenerated(newQuestionnaire);
    return newQuestionnaire;
}
exports.generateInitialQuestionnaire = generateInitialQuestionnaire;
function markQuestionnaireAsInitialGenerated(questionnaire) {
    // mark all elements as initialGenerated
    [...questionnaire.questionnairePages, questionnaire.signaturePage].forEach((page, pageNum) => {
        page.pageElements.forEach(pageElm => {
            pageElm.initialGenerated = true;
        });
    });
    questionnaire.initialGeneratedHash = (0, types_1.stableHashQuestionnaire)(questionnaire);
}
exports.markQuestionnaireAsInitialGenerated = markQuestionnaireAsInitialGenerated;
