/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./QuestionnaireElement.vue?vue&type=template&id=613ddd95&scoped=true&lang=pug&"
import script from "./QuestionnaireElement.vue?vue&type=script&lang=ts&"
export * from "./QuestionnaireElement.vue?vue&type=script&lang=ts&"
import style1 from "./QuestionnaireElement.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../vui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613ddd95",
  null
  
)

export default component.exports